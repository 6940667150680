import _ from 'lodash'
import { ColGroupDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { ClientSideNumberFilter } from '../../../../containers/BulkSheetView/components/filter'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import {
  DEFAULT_COLUMN_GROUP_DEF,
  DESCRIPTION_COLUMN_DEF,
  ESTIMATE_COLUMN_GROUP_DEF,
  useEexplanationColumnGroupDef,
  generateCustomEnumColumnDef,
  getAssignmentColumnGroupDef,
  getDisplayNameColumnDef,
  getInformationColumnGroupDef,
  getNameColumnGroupDef,
  getProductivityColumnGroupDef,
  PATH_COLUMN_GROUP_DEF,
  PROGRESS_COLUMN_GROUP_DEF,
  PropsForGetColumnDefs,
  STORYPOINT_COLUMN_GROUP_DEF,
  TAG_EXTERNAL_ID,
  TERM_COLUMN_GROUP_DEF,
  CHANGE_LOG_COLUMN_GROUP_DEF,
} from './common'
import { useMemo } from 'react'

const RISK_COLUMN_GROUP_DEF: FlagxsColumnDef = {
  externalId: 'risk.riskInformation',
  headerName: intl.formatMessage({ id: 'risk' }),
  children: [
    generateCustomEnumColumnDef(
      'risks.riskType',
      'riskType',
      'risks.riskType',
      'riskType'
    ),
    {
      field: 'score',
      headerName: intl.formatMessage({ id: 'risks.score' }),
      width: 90,
      editable: false,
      cellClass: 'ag-numeric-cell',
      cellStyle: { justifyContent: 'flex-end' },
      filter: ClientSideNumberFilter,
      floatingFilter: true,
    },
    generateCustomEnumColumnDef(
      'risks.probability',
      'probability',
      'risks.probability',
      'riskProbability'
    ),
    generateCustomEnumColumnDef(
      'risks.impact',
      'impact',
      'risks.impact',
      'riskImpact'
    ),
    generateCustomEnumColumnDef(
      'risks.strategy',
      'strategy',
      'risks.strategy',
      'riskStrategy'
    ),
    DESCRIPTION_COLUMN_DEF,
  ],
} as FlagxsColumnDef

const removeChildDef = (
  src: ColGroupDef,
  targetChildExternalId: string | undefined
) => {
  if (!targetChildExternalId) return src
  const targetGroupDef = _.cloneDeep(src)
  const targetIndex = targetGroupDef.children.findIndex(
    (c: FlagxsColumnDef) => {
      return c.externalId === targetChildExternalId
    }
  )
  if (0 <= targetIndex) {
    targetGroupDef.children.splice(targetIndex, 1)
  }
  return targetGroupDef
}

export const useRiskColumnDefs = ({
  projectUuid,
  onClickedStatusCell,
  addRow,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  context,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const dispNameColDef = getDisplayNameColumnDef({
    reloadSingleRow,
    submitSingleRow,
  })

  const infoGroupDef = _.cloneDeep(
    getInformationColumnGroupDef({
      onClickedStatusCell,
      addRow,
    })
  )
  const tagColumnIndex = infoGroupDef.children.findIndex(
    (c: FlagxsColumnDef) => c.externalId === TAG_EXTERNAL_ID
  )
  if (0 <= tagColumnIndex) {
    infoGroupDef.children.splice(tagColumnIndex, 0, dispNameColDef)
  }

  const nameColGroupDef = removeChildDef(
    getNameColumnGroupDef({
      reloadSingleRow,
      submitSingleRow,
      context,
    }) as ColGroupDef,
    dispNameColDef.externalId
  )

  const explanationColumnGroupDef = useEexplanationColumnGroupDef()
  const explanationColGroupDef = removeChildDef(
    explanationColumnGroupDef as ColGroupDef,
    DESCRIPTION_COLUMN_DEF.externalId
  )

  const columnDefs = useMemo(
    () => [
      DEFAULT_COLUMN_GROUP_DEF,
      infoGroupDef,
      PATH_COLUMN_GROUP_DEF,
      RISK_COLUMN_GROUP_DEF,
      nameColGroupDef,
      explanationColGroupDef,
      getAssignmentColumnGroupDef(projectUuid, context),
      STORYPOINT_COLUMN_GROUP_DEF,
      ESTIMATE_COLUMN_GROUP_DEF,
      PROGRESS_COLUMN_GROUP_DEF,
      getProductivityColumnGroupDef(onClickedActualHourCell),
      TERM_COLUMN_GROUP_DEF,
      CHANGE_LOG_COLUMN_GROUP_DEF,
    ],
    [
      projectUuid,
      context,
      infoGroupDef,
      nameColGroupDef,
      explanationColGroupDef,
      onClickedActualHourCell,
    ]
  )
  return columnDefs as FlagxsColumnDef[]
}
