import { useMemo } from 'react'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import {
  DEFAULT_COLUMN_GROUP_DEF,
  ESTIMATE_COLUMN_GROUP_DEF,
  useEexplanationColumnGroupDef,
  getAssignmentColumnGroupDef,
  getInformationColumnGroupDef,
  getNameColumnGroupDef,
  getProductivityColumnGroupDef,
  PATH_COLUMN_GROUP_DEF,
  PROGRESS_COLUMN_GROUP_DEF,
  PropsForGetColumnDefs,
  STORYPOINT_COLUMN_GROUP_DEF,
  TERM_COLUMN_GROUP_DEF,
  CHANGE_LOG_COLUMN_GROUP_DEF,
} from './common'

export const useGeneralColumnDefs = ({
  projectUuid,
  onClickedStatusCell,
  addRow,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  context,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const explanationColumnGroupDef = useEexplanationColumnGroupDef()
  const columnDefs = useMemo(
    () => [
      DEFAULT_COLUMN_GROUP_DEF,
      getInformationColumnGroupDef({
        onClickedStatusCell,
        addRow,
      }),
      PATH_COLUMN_GROUP_DEF,
      getNameColumnGroupDef({
        reloadSingleRow,
        submitSingleRow,
        context,
      }),
      explanationColumnGroupDef,
      getAssignmentColumnGroupDef(projectUuid, context),
      STORYPOINT_COLUMN_GROUP_DEF,
      ESTIMATE_COLUMN_GROUP_DEF,
      PROGRESS_COLUMN_GROUP_DEF,
      getProductivityColumnGroupDef(onClickedActualHourCell),
      TERM_COLUMN_GROUP_DEF,
      CHANGE_LOG_COLUMN_GROUP_DEF,
    ],
    [
      projectUuid,
      onClickedStatusCell,
      addRow,
      onClickedActualHourCell,
      reloadSingleRow,
      submitSingleRow,
      context,
      explanationColumnGroupDef,
    ]
  )
  return columnDefs
}
