import { useMemo } from 'react'
import {
  CellKeyDownEvent,
  GridOptions,
  ProcessCellForExportParams,
} from 'ag-grid-community'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { format as commentFormat } from '../../../../utils/comment'
import { normalize } from '../../../../utils/multilineText'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  ColumnType,
  columnTypes,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import { MULTI_PROPERTY_DELIMITER, WbsItemSearchRow } from '../wbsItemSearch'
import {
  DEFAULT_COLUMN_DEF,
  useColumnDefs,
  PropsForGetColumnDefs,
} from './columnDefs'
import store from '../../../../store'
import { SprintDetail } from '../../../../lib/functions/sprint'

export const useWbsItemSearchGridOptions = ({
  projectUuid,
  selectColumnOptionMap: selectColumnOptions,
  onClickedStatusCell,
  onClickedActualHourCell,
  reloadSingleRow,
}: PropsForGetColumnDefs): GridOptions => {
  const columnDefs = useColumnDefs({
    projectUuid,
    selectColumnOptionMap: selectColumnOptions,
    onClickedStatusCell,
    onClickedActualHourCell,
    reloadSingleRow,
  })

  const gridOptions: GridOptions = useMemo(
    () => ({
      context: {
        ...selectColumnOptions,
        aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
      },
      treeData: false,
      rowHeight: ROW_HEIGHT.SMALL,
      // Row actions
      enterMovesDownAfterEdit: true,
      rowDragManaged: false,
      rowDragMultiRow: false,
      suppressLastEmptyLineOnPaste: true,
      suppressMoveWhenRowDragging: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: DEFAULT_COLUMN_DEF,
      columnDefs,
      excelStyles: [
        {
          id: 'dateType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd',
          },
        },
        {
          id: 'dateTimeType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd hh:MM:ss',
          },
        },
      ],
      // Copy & paste
      processCellForClipboard,
      processCellFromClipboard,
      processDataFromClipboard,
      // Footer
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['sum', 'count'],
            },
            align: 'left',
          },
        ],
      },
      localeText: {
        sum: intl.formatMessage({
          id: 'bulksheet.statusPanel.sum',
        }),
        count: intl.formatMessage({
          id: 'bulksheet.statusPanel.count',
        }),
        totalAndFilteredRows: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
        }),
        of: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
        }),
      },
    }),
    [columnDefs, selectColumnOptions]
  )
  return gridOptions
}

export const processCellForClipboard = (
  params: ProcessCellForExportParams<WbsItemSearchRow>
) => {
  const { api, column, value, node, context } = params
  const colDef = column.getColDef()
  const { field, type, cellRendererParams, cellEditorParams, valueFormatter } =
    colDef
  const row: WbsItemSearchRow | undefined = node?.data

  if (field) {
    if (['action', 'deliverableAttachmentSummary'].includes(field)) {
      return ''
    }
    if (field === 'wbsItem.type') {
      return row?.wbsItem?.baseWbsItemType?.getNameWithSuffix() ?? ''
    }
    if (field === 'wbsItem.ticketType') {
      const wbsItemType = row?.wbsItem?.wbsItemType
      return wbsItemType?.isTicket() ? wbsItemType?.name : ''
    }
    if (field === 'wbsItem.tags') {
      return value?.map(v => v.name).join(TAG_DELIMITER)
    }
    if (field === 'wbsItem.description') {
      return `"${normalize(params.value || '')}"`
    }
    if (field === 'commentSummary.latestComment') {
      return value ? `"${commentFormat(params.value)}"` : ' '
    }
    if (field === 'wbsItem.watchers') {
      return value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER)
    }
  }
  if (!type) {
    if (typeof value === 'object') {
      return value.name ?? value.displayName
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    return (
      getLabel(value?.nameI18n) ||
      value?.name ||
      value?.displayName ||
      value?.officialName
    )
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const option = options.find(v => v.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }

  // TODO: Support Entity Excention Column
  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellRendererParams, cellEditorParams, valueFormatter } =
    colDef
  const row: WbsItemSearchRow | undefined = node?.data

  if (field) {
    if (field === 'wbsItem.tags') {
      const projectUuid = store.getState().project.selected
      if (!projectUuid) return
      const names = value.split(TAG_DELIMITER).filter(v => !!v)
      const options = store.getState().tag[projectUuid]
      return options.filter(v => names.includes(v.name))
    }
    if (field === 'wbsItem.watchers') {
      const names = value.split(MULTI_PROPERTY_DELIMITER).filter(v => !!v)
      const options = context['member']
      return options.filter(o => names.includes(o.name))
    }
    if (field === 'wbsItem.sprint') {
      const team = node?.data?.wbsItem.team
      if (!team) return undefined
      return context.sprint?.find(
        (v: SprintDetail) => v.teamUuid === team.uuid && v.name === value
      )
    }
  }
  if (!type) {
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    if (cellEditorParams.entity) {
      const options = context[cellEditorParams.entity]
      return options.find(o =>
        [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
          .filter(v => !!v)
          .includes(value)
      )
    }
    return value
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const target = options.find(o =>
      [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
    )
    return target?.value
  }

  // TODO: Support Entity Excention Column
  return value
}
