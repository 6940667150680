import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { useCallback } from 'react'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { format as commentFormat } from '../../../../utils/comment'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { WbsItemSearchRow } from '../wbsItemSearch'

export const MULTI_PROPERTY_DELIMITER: string = ','

export const useExcel = (gridOptions: GridOptions | undefined) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams<WbsItemSearchRow>) => {
      if (!params.node) return undefined
      const columnId: string = params.column.getColId()
      const colDef = params.column.getColDef()
      const { cellRendererParams, cellEditorParams, type } = colDef
      const row: WbsItemSearchRow | undefined = params.node.data

      if (columnId === 'rowNumber') {
        const rendererParams = cellRendererParams({ node: params.node })
        return rendererParams.value
      }
      if (columnId === 'wbsItem.type') {
        return row?.wbsItem?.baseWbsItemType?.getNameWithSuffix() ?? ' '
      }
      if (columnId === 'wbsItem.ticketType') {
        const wbsItemType = row?.wbsItem?.wbsItemType
        return wbsItemType?.isTicket() ? wbsItemType?.name : ' '
      }
      if (columnId === 'wbsItem.tags') {
        return params.value?.map(v => v.name)?.join(TAG_DELIMITER)
      }
      if (columnId === 'parentWbsItem') {
        return row?.parentWbsItem?.displayName ?? ' '
      }
      if (columnId === 'commentSummary.latestComment') {
        return params.value ? commentFormat(params.value) : ''
      }
      if (columnId === 'wbsItem.watchers') {
        return (
          params.value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER) ?? ''
        )
      }
      if (['createdBy', 'updatedBy'].includes(columnId)) {
        return params.value?.name ?? undefined
      }
      return undefined
    },
    []
  )

  const onExcelExport = useCallback(
    (colIds: string[]) => {
      if (!gridOptions) return

      exportExcel({
        fileNamePrefix: 'wbsItem_search_edit',
        gridOptions,
        exportColIds: colIds,
        getCustomExportValue: customExportValues,
      })
    },
    [gridOptions, customExportValues]
  )

  return onExcelExport
}
