import { useMemo } from 'react'
import {
  CellClassParams,
  CellStyle,
  EditableCallbackParams,
  ICellRendererParams,
  IDoesFilterPassParams,
  NewValueParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  AggregateField,
  WbsItemStatus,
} from '../../../domain/entity/WbsItemEntity'
import { dateVoService } from '../../../domain/value-object/DateVO'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { filterValuesByCombination } from '../../../lib/commons/customEnum'
import { getLabel } from '../../../lib/commons/i18nLabel'
import ProjectMemberAPI, {
  ProjectMemberProps,
} from '../../../lib/functions/projectMember'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import {
  FilterSortOrder,
  SprintDetail,
  SprintStatus,
} from '../../../lib/functions/sprint'
import { TagForWbsItem } from '../../../lib/functions/tag'
import { TeamProps } from '../../../lib/functions/team'
import {
  getWbsItemStatusColorCode,
  getWbsItemStatusDeepColorCode,
  getWbsItemStatusLabel,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import { intl } from '../../../i18n'
import store from '../../../store'
import { Comment, CommentSummary } from '../../../store/comments'
import { BackgroundColor, TextColor } from '../../../styles/commonStyles'
import { AttachmentSummary } from '../../../utils/attachment'
import BoolExpression from '../../../utils/boolExpression'
import { DateTerm } from '../../../utils/date'
import { toInteger, toNumber } from '../../../utils/number'
import objects from '../../../utils/objects'
import DateVO from '../../../vo/DateVO'
import {
  CustomEnumCellEditor,
  DateCellEditor,
  EntitySearchCellEditor,
  MultiAutocompleteCellEditor,
  TagCellEditor,
} from '../../containers/BulkSheetView/components/cellEditor'
import { IDateCellEditorParams } from '../../containers/BulkSheetView/components/cellEditor/DateCellEditor'
import {
  AttachmentCellRenderer,
  CustomEnumCellRenderer,
  DefaultCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanActionCellRenderer,
  ProjectPlanStatusCellRenderer,
  TicketTypeCellRenderer,
} from '../../containers/BulkSheetView/components/cellRenderer'
import { NumberCellRenderer } from '../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import { TagCellRenderer } from '../../containers/BulkSheetView/components/cellRenderer/TagCellRenderer'
import { TicketTypeCellValue } from '../../containers/BulkSheetView/components/cellRenderer/TicketTypeCellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  ClientSideTextFilter,
  ServerSideUuidFilter,
} from '../../containers/BulkSheetView/components/filter'
import { ListRow } from '../../containers/BulkSheetView/model'
import { ColumnType } from '../../containers/commons/AgGrid'
import MultiAutocompleteCell from '../../containers/commons/AgGrid/components/cell/common/multiAutocomplete'
import { multiAutocompleteCellFilterValueFormatter } from '../../containers/commons/AgGrid/components/cell/common/multiAutocomplete/cellEditor'
import attachmentCellFilter from '../../containers/commons/AgGrid/components/cell/custom/attachment/attachmentCellFilter'
import CommentCell from '../../containers/commons/AgGrid/components/cell/custom/comment'
import CommentCellFilter from '../../containers/commons/AgGrid/components/cell/custom/comment/CommentCellFilter'
import { DetailCellFilter } from '../../containers/commons/AgGrid/components/cell/custom/detail'
import WbsItemBasicCellRenderer from '../../containers/commons/AgGrid/components/cell/custom/wbsItemName/basicCellRenderer'
import {
  WbsItemCellRenderer,
  Props as WbsItemCellRendererProps,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemName/wbsItemCellRenderer'
import {
  comparator,
  WbsItemTypeCellValue,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { customHeaderTemplate } from '../../containers/commons/AgGrid/components/header/CustomHeader'
import { EntitySearchValue } from '../../containers/meta/repositories'
import { BulkSheetProperty } from './bulksheetProperty'
import {
  floatValueFormatter,
  useCustomEnumColumn,
  useMultiLineTextColum,
} from './common'
import { commonValueSetter, defaultValueSetter } from '.'

export const useUuid = (): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field: 'uuid',
      hide: true,
      suppressColumnsToolPanel: true,
      filter: ServerSideUuidFilter,
    }),
    []
  )
  return property
}

// Base Infomation -----

export const useCode = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.code' }),
      hide: true,
      pinned: true,
      width: 100,
      editable: params => params.data.added,
      cellRenderer: DefaultCellRenderer,
      cellEditorParams: {
        uiMeta: {
          propertyType: PropertyType.Text,
          charactersAllowed: 'a-zA-Z0-9_-',
          maxLength: 32,
        } as Partial<FunctionProperty>,
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
    }),
    [field]
  )
  return property
}

export const useType = <TRow extends ListRow>({
  field,
  getBaseWbsItemType,
  getTypeIndex,
}: {
  field: string
  getBaseWbsItemType: (row: TRow | undefined) => WbsItemTypeVO | undefined
  getTypeIndex: (row: TRow | undefined) => number | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.type' }),
      hide: true,
      pinned: true,
      width: 100,
      editable: false,
      valueGetter: (params: ValueGetterParams<TRow>) => {
        const baseType = getBaseWbsItemType(params.data)
        const typeIndex = getTypeIndex(params.data)
        if (!baseType) return {}
        return {
          wbsItemType: baseType,
          typeIndex,
        } as WbsItemTypeCellValue
      },
      valueFormatter: (params: ValueFormatterParams<TRow>) =>
        getBaseWbsItemType(params.data)?.getNameWithSuffix(),
      filter: ClientSideSelectFilter,
      filterParams: {
        getValue: (option: WbsItemTypeCellValue) =>
          option?.wbsItemType?.getNameWithSuffix() ?? '',
        getLabel: (option: WbsItemTypeCellValue) =>
          option?.wbsItemType?.getNameWithSuffix() ?? '',
        sortValues: (
          uiMeta: FunctionProperty,
          options: WbsItemTypeCellValue[],
          context: { [key: string]: CustomEnumValue[] }
        ) => {
          return options.sort(sortWbsItemType)
        },
      },
      comparator: sortWbsItemType,
      floatingFilter: true,
    }),
    [field, getBaseWbsItemType, getTypeIndex]
  )
  return property as BulkSheetProperty
}

export const useTicketType = <TRow extends ListRow>({
  field,
  getBaseWbsItemType,
  getTypeIndex,
}: {
  field: string
  getBaseWbsItemType: (row: TRow | undefined) => WbsItemTypeVO | undefined
  getTypeIndex: (row: TRow | undefined) => number | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.ticketType' }),
      hide: true,
      pinned: true,
      width: 100,
      editable: false,
      sortable: true,
      cellRenderer: TicketTypeCellRenderer,
      valueGetter: (params: ValueGetterParams<TRow>) => {
        const baseType = getBaseWbsItemType(params.data)
        const typeIndex = getTypeIndex(params.data)
        if (!baseType || !baseType.isTicket()) return
        return {
          wbsItemType: baseType,
          typeIndex,
        } as TicketTypeCellValue
      },
      valueFormatter: (params: ValueFormatterParams<TRow>) =>
        getBaseWbsItemType(params.data)?.getNameWithSuffix(),
      filter: ClientSideSelectFilter,
      filterParams: {
        getValue: (option: TicketTypeCellValue) =>
          option?.wbsItemType?.name ?? '',
        getLabel: (option: TicketTypeCellValue) =>
          option?.wbsItemType?.name ?? '',
        sortValues: (
          uiMeta: FunctionProperty,
          options: TicketTypeCellValue[],
          context: { [key: string]: CustomEnumValue[] }
        ) => {
          return options.sort(sortWbsItemType)
        },
      },
      floatingFilter: true,
      comparator: sortWbsItemType,
    }),
    [field, getBaseWbsItemType, getTypeIndex]
  )
  return property as BulkSheetProperty
}

export const useStatus = ({
  field,
  subStatusField,
  statusCombinedValuePath,
  statusCustomEnumCode = 'status',
  substatusCustomEnumCode = 'substatus',
}: {
  field: string
  subStatusField: string
  statusCombinedValuePath: (combinedEnumCode: string) => string | undefined
  statusCustomEnumCode?: string
  substatusCustomEnumCode?: string
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.status' }),
      pinned: true,
      width: 150,
      editable: true,
      type: [ColumnType.customEnum],
      cellEditor: CustomEnumCellEditor,
      cellEditorParams: {
        customEnumCode: statusCustomEnumCode,
        combinedValuePath: statusCombinedValuePath,
      },
      valueSetter: (params: ValueSetterParams) => {
        return commonValueSetter(params, (params: ValueSetterParams) => {
          objects.setValue(
            params.data,
            field,
            params.newValue ?? WbsItemStatus.TODO
          )
          // Update substatus
          const { combinedValuePath } = params.colDef.cellEditorParams
          const substatusOptions = filterValuesByCombination(
            params.context[substatusCustomEnumCode],
            code => objects.getValue(params.data, combinedValuePath?.(code)),
            true
          )
          objects.setValue(
            params.data,
            subStatusField,
            substatusOptions?.[0]?.value
          )
        })
      },
      cellRenderer: ProjectPlanStatusCellRenderer,
      cellStyle: (params: CellClassParams) => {
        const status = objects.getValue(params.data, field)
        if (!status) {
          return { backgroundColor: '#F7F7F7' }
        }
        return {
          backgroundColor: `${getWbsItemStatusColorCode(status)}`,
        }
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: (params: IDoesFilterPassParams) => {
          const status = objects.getValue(params.node.data, field)
          return {
            status,
            backgroundColor: `${getWbsItemStatusDeepColorCode(status)}`,
          } as StatusFilterParams
        },
        getValue: (v?: StatusFilterParams) => v?.status,
        getLabel: (v?: StatusFilterParams) =>
          v ? getWbsItemStatusLabel(v.status) : v,
        sortValues: (uiMeta, options: StatusFilterParams[]) => {
          return options.sort(
            (valueA: StatusFilterParams, valueB: StatusFilterParams) =>
              wbsItemStatusComparator(valueA.status, valueB.status)
          )
        },
      },
      comparator: wbsItemStatusComparator,
    }),
    [
      field,
      subStatusField,
      statusCustomEnumCode,
      substatusCustomEnumCode,
      statusCombinedValuePath,
    ]
  )
  return property
}

export const useSubstatus = ({
  field,
  statusCombinedValuePath,
  customEnumCode = 'substatus',
}: {
  field: string
  statusCombinedValuePath: (combinedEnumCode: string) => string | undefined
  customEnumCode?: string
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.substatus' }),
      pinned: true,
      hide: true,
      width: 90,
      editable: true,
      type: [ColumnType.customEnum],
      cellEditor: CustomEnumCellEditor,
      cellEditorParams: {
        customEnumCode,
        combinedValuePath: statusCombinedValuePath,
      },
      valueSetter: customEnumValueSetter,
      cellRenderer: CustomEnumCellRenderer,
      cellRendererParams: { customEnumCode: customEnumCode },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: ({ node, context }: { node: RowNode; context: any }) => {
          const options = context ? context[customEnumCode] : []
          const value = objects.getValue(node.data, field)
          return options?.find(o => o.value === value)
        },
        getValue: (v?: CustomEnumValue) => v?.value,
        getLabel: (v?: CustomEnumValue) =>
          v?.nameI18n ? getLabel(v.nameI18n) : v?.name,
      },
    }),
    [field, customEnumCode, statusCombinedValuePath]
  )
  return property
}

export const useCommentSummaryAction = <TRow extends ListRow>({
  field,
  getCommentSummary,
  getWbsItem,
}: {
  field: string
  getCommentSummary: (row: TRow | undefined) => CommentSummary | undefined
  // TODO: The retrieved wbsItem is specified in the mapRowDataForCommentHeader in the ProjectPlanActionCellRenderer.
  // The argument of mapRowDataForCommentHeader is WbsItemRow
  getWbsItem: (row: TRow | undefined) => WbsItemRow | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.action' }),
      hide: false,
      pinned: true,
      sortable: false,
      width: 100,
      cellRenderer: ProjectPlanActionCellRenderer,
      cellRendererParams: {
        getCommentSummary,
        getWbsItem,
        openComment: true,
      },
      valueGetter: (params: ValueGetterParams<TRow>) =>
        getCommentSummary(params.data)?.hasComment ||
        getWbsItem(params.data)?.uuid, // This valueGetter is to notify the action cell of the necessary that it needs to be refreshed.
      filter: DetailCellFilter,
      floatingFilter: true,
    }),
    [field, getCommentSummary, getWbsItem]
  )
  return property
}

export const useAttachments = <TRow extends ListRow>({
  field,
  getWbsItemUuid,
  getAttachmentSummary,
}: {
  field: string
  getWbsItemUuid?: (node: RowNode<TRow>) => string | undefined
  getAttachmentSummary?: (row: TRow) => AttachmentSummary | undefined
}) => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.attachments' }),
      pinned: true,
      width: 55,
      cellRenderer: AttachmentCellRenderer,
      filter: attachmentCellFilter,
      floatingFilter: true,
      cellRendererParams: {
        getWbsItemUuid,
        getAttachmentSummary,
      },
      comparator: (
        a: AttachmentSummary | undefined,
        b: AttachmentSummary | undefined
      ) => {
        if (!a && !b) return 0
        if (!a) return -1
        if (!b) return 1
        if (0 < a.totalAttachmentCount) return 1
        if (0 < b.totalAttachmentCount) return -1
        return 0
      },
    }),
    [field, getWbsItemUuid, getAttachmentSummary]
  )
  return property
}

export const useTags = <TRow extends ListRow>({
  field,
  getWbsItemProjectUuid,
}: {
  field: string
  getWbsItemProjectUuid: (data: TRow | undefined) => string | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.tags' }),
      width: 90,
      editable: true,
      type: [ColumnType.multiSelect],
      cellEditor: TagCellEditor,
      cellEditorParams: {
        projectUuidExtractor: getWbsItemProjectUuid,
      },
      cellRenderer: TagCellRenderer,
      cellRendererParams: {
        uiMeta: {},
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value
        },
        getValue: option => option?.uuid,
        getLabel: option => option?.name,
        sortValues: (uiMeta, options) => {
          const projectUuid = store.getState().project.selected
          if (!projectUuid) return options
          const tags = store.getState().tag[projectUuid]
          if (!tags) return options
          return options.sort((a, b) => {
            return (
              tags.findIndex(e => e.uuid === a.uuid) -
              tags.findIndex(e => e.uuid === b.uuid)
            )
          })
        },
      },
      comparator: sortTag,
    }),
    [field, getWbsItemProjectUuid]
  )
  return property
}

// Path ------
export const usePath = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.path' }),
      hide: true,
      pinned: true,
      width: 120,
      filter: ClientSideTextFilter,
      floatingFilter: true,
      cellStyle: {
        direction: 'rtl',
      },
    }),
    [field]
  )
  return property
}

export const useParentWbsItem = <TRow extends ListRow>({
  field,
  getParentWbsItem,
}: {
  field: string
  getParentWbsItem: (row: TRow | undefined) => Partial<WbsItemRow> | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.parentWbsItem' }),
      pinned: true,
      hide: true,
      editable: false,
      valueGetter: (params: ValueGetterParams) => {
        return getParentWbsItem(params.data)
      },
      cellRenderer: WbsItemBasicCellRenderer,
      cellRendererParams: {
        uiMeta: {} as Partial<FunctionProperty>,
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
      filterValueGetter: (params: ValueGetterParams) => {
        return getParentWbsItem(params.data)?.displayName
      },
      comparator: (
        valueA: Partial<WbsItemRow>,
        valueB: Partial<WbsItemRow>
      ) => {
        return (valueA?.displayName ?? '').localeCompare(
          valueB?.displayName ?? ''
        )
      },
    }),
    [field, getParentWbsItem]
  )
  return property
}

// Name ------
export const useDisplayName = ({
  field,
  wbsItemCellRendererParams,
}: {
  field: string
  wbsItemCellRendererParams?: Partial<WbsItemCellRendererProps>
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.displayName' }),
      width: 200,
      pinned: true,
      cellRenderer: WbsItemCellRenderer,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        uiMeta: {
          requiredIf: BoolExpression.of(true),
        } as Partial<FunctionProperty>,
        ...wbsItemCellRendererParams,
      },
      cellEditorParams: {
        uiMeta: {
          propertyType: PropertyType.Text,
          maxLength: 256,
        } as Partial<FunctionProperty>,
      },
      editable: true,
      valueGetter: (params: ValueGetterParams) => {
        return objects.getValue(params.data, field) ?? ''
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
    }),
    [field, wbsItemCellRendererParams]
  )
  return property
}

export const usePriority = ({
  field,
  customEnumCode = 'priority',
  customEnumOptions,
}: {
  field: string
  customEnumCode?: string
  customEnumOptions?: CustomEnumValue[] // TODO: GridOption.context cannot be obtained from ColDef.comprator, so pass it as an argument.
}): BulkSheetProperty => {
  const cosutomEnumColumn = useCustomEnumColumn({
    field,
    customEnumCode,
    customEnumOptions,
  })
  const property = useMemo(
    () => ({
      ...cosutomEnumColumn,
      headerName: intl.formatMessage({ id: 'wbsItem.property.priority' }),
    }),
    [cosutomEnumColumn]
  )
  return property
}

export const useDifficulty = ({
  field,
  customEnumCode = 'difficulty',
  customEnumOptions,
}: {
  field: string
  customEnumCode?: string
  customEnumOptions?: CustomEnumValue[] // TODO: GridOption.context cannot be obtained from ColDef.comprator, so pass it as an argument.
}): BulkSheetProperty => {
  const cosutomEnumColumn = useCustomEnumColumn({
    field,
    customEnumCode,
    customEnumOptions,
  })
  const property = useMemo(
    () => ({
      ...cosutomEnumColumn,
      headerName: intl.formatMessage({ id: 'wbsItem.property.difficulty' }),
    }),
    [cosutomEnumColumn]
  )
  return property
}

// Dscription Commont ------
export const useDescription = (field: string): BulkSheetProperty => {
  const multiLineColumnDef = useMultiLineTextColum(field)
  const property = useMemo(
    () => ({
      ...multiLineColumnDef,
      headerName: intl.formatMessage({ id: 'wbsItem.property.description' }),
      hide: true,
      cellRendererParams: {
        uiMeta: {
          propertyType: PropertyType.MultiLineText,
          maxLength: 10000,
        } as Partial<FunctionProperty>,
      },
    }),
    [multiLineColumnDef]
  )
  return property
}

export const useLatestComment = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.latestComment' }),
      hide: true,
      cellRenderer: CommentCell.cellRenderer,
      filter: CommentCellFilter,
      floatingFilter: true,
      comparator: (
        valueA: Comment | undefined,
        valueB: Comment | undefined
      ) => {
        const valA: number = valueA?.createdAt ?? 0
        const valB: number = valueB?.createdAt ?? 0
        return valA - valB
      },
    }),
    [field]
  )
  return property
}

// Assignment -----
export const useTeam = ({
  field,
  optionKey = 'team',
  gridOptionContext,
}: {
  field: string
  optionKey?: string
  gridOptionContext: { [key: string]: any } // TODO: GridOption.context cannot be obtained from ColDef.comprator, so pass it as an argument.
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.team' }),
      width: 120,
      editable: true,
      type: [ColumnType.autocomplete],
      cellRenderer: EntitySearchCellRenderer,
      cellEditor: EntitySearchCellEditor,
      cellEditorParams: { entity: optionKey },
      suppressKeyboardEvent: params =>
        (!params.editing &&
          ['Delete', 'Backspace'].includes(params.event.key)) ||
        (params.editing && params.event.key === 'Enter'),
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: ({ node, context }) => {
          const val = objects.getValue(node.data, field)
          return typeof val === 'string' ? undefined : val // Exclude unselected data
        },
        getValue: (v?: TeamProps) => v?.uuid,
        getLabel: (v?: TeamProps) => v?.displayName,
        sortValues: (uiMeta, options) => {
          if (!gridOptionContext) return options
          const allOptions: TeamProps[] = gridOptionContext[optionKey]
          if (!allOptions) return options
          return options.sort((a: TeamProps, b: TeamProps) => {
            return (
              allOptions.findIndex(e => e.uuid === a?.uuid) -
              allOptions.findIndex(e => e.uuid === b?.uuid)
            )
          })
        },
      },
      comparator: (a: TeamProps, b: TeamProps) => {
        const options: TeamProps[] = gridOptionContext[optionKey]
        if (!options) {
          return (a?.displayName ?? '').localeCompare(b?.displayName ?? '')
        }
        return (
          options.findIndex(e => e.uuid === a?.uuid) -
          options.findIndex(e => e.uuid === b?.uuid)
        )
      },
    }),
    [field, optionKey, gridOptionContext]
  )
  return property
}

export const useAccountable = ({
  field,
  optionKey = 'member',
}: {
  field: string
  optionKey?: string
}): BulkSheetProperty => {
  const projectMemberColumn = useProjectMember({
    field,
    optionKey,
  })
  const property = useMemo(
    () => ({
      ...projectMemberColumn,
      headerName: intl.formatMessage({ id: 'wbsItem.property.accountable' }),
    }),
    [projectMemberColumn]
  )
  return property
}

export const useResponsible = ({
  field,
  optionKey = 'member',
}: {
  field: string
  optionKey?: string
}): BulkSheetProperty => {
  const projectMemberColumn = useProjectMember({
    field,
    optionKey,
  })
  const property = useMemo(
    () => ({
      ...projectMemberColumn,
      headerName: intl.formatMessage({ id: 'wbsItem.property.responsible' }),
    }),
    [projectMemberColumn]
  )
  return property
}

export const useAssignee = ({
  field,
  optionKey = 'member',
}: {
  field: string
  optionKey?: string
}): BulkSheetProperty => {
  const projectMemberColumn = useProjectMember({
    field,
    optionKey,
  })
  const property = useMemo(
    () => ({
      ...projectMemberColumn,
      headerName: intl.formatMessage({ id: 'wbsItem.property.assignee' }),
    }),
    [projectMemberColumn]
  )
  return property
}

export const useWatchers = <TRow extends ListRow>({
  field,
}: {
  field: string
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.watcher' }),
      hide: true,
      width: 120,
      editable: true,
      type: [ColumnType.autocomplete],
      valueSetter: (params: ValueSetterParams) => {
        const watchars: ProjectMemberProps[] = params.newValue
        params.newValue = watchars.sort(
          (valueA: ProjectMemberProps, valueB: ProjectMemberProps) =>
            (valueA?.name ?? '').localeCompare(valueB?.name ?? '')
        )
        return defaultValueSetter(params)
      },
      cellRenderer: MultiAutocompleteCell.cellRenderer,
      cellRendererParams: { showIcon: true },
      cellEditor: MultiAutocompleteCellEditor,
      cellEditorParams: {
        search: async (text: string, projectUuid: string) =>
          ProjectMemberAPI.search(text, { projectUuid }),
        label: option => option.name,
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueFormatter: multiAutocompleteCellFilterValueFormatter,
        getValue: option => new EntitySearchValue(option).toString(),
        getLabel: option => new EntitySearchValue(option).toString(),
      },
      comparator: sortWatchers,
    }),
    [field]
  )
  return property
}

// Estimated -----
// TODO: The types of the Workload variables for WbsItemRow and NewWbsItemRow are different, so they cannot be commonized.
// TODO: Should use NewWbsItemRow?
export const useEstimatedWorkLoadDeliverable = <TRow extends ListRow>({
  field,
  getWbsItemType,
}: {
  field: string
  getWbsItemType: (row: TRow | undefined) => WbsItemTypeVO | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.estimated.deliverable',
      }),
      width: 80,
      editable: (params: EditableCallbackParams<TRow>) =>
        getWbsItemType(params.data)?.isDeliverable(),
      cellClass: 'ag-numeric-cell',
      cellStyle: { justifyContent: 'flex-end' },
      cellRenderer: NumberCellRenderer,
      cellRendererParams: {
        numberWithCommas: true,
        decimalPoints: WORKLOAD_VALUE_DIGITS,
      },
      cellEditorParams: {
        uiMeta: {
          propertyType: PropertyType.Number,
          maxLength: MAX_LENGTH_ESTIMATED,
          charactersAllowed: '.0-9',
        } as Partial<FunctionProperty>,
      },
      valueParser: (params: ValueParserParams) => toNumber(params.newValue),
      filter: ClientSideNumberFilter,
      floatingFilter: true,
    }),
    [field, getWbsItemType]
  )
  return property as BulkSheetProperty
}

// TODO: The types of the Workload variables for WbsItemRow and NewWbsItemRow are different, so they cannot be commonized.
// TODO: Should use NewWbsItemRow?
export const useEstimatedWorkLoadTask = <TRow extends ListRow>({
  field,
  getWbsItemType,
}: {
  field: string
  getWbsItemType: (row: TRow | undefined) => WbsItemTypeVO | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.estimated.task',
      }),
      width: 80,
      editable: (params: EditableCallbackParams<TRow>) =>
        getWbsItemType(params.data)?.isTask(),
      cellRenderer: NumberCellRenderer,
      cellRendererParams: {
        numberWithCommas: true,
        decimalPoints: WORKLOAD_VALUE_DIGITS,
      },
      cellEditorParams: {
        uiMeta: {
          propertyType: PropertyType.Number,
          maxLength: MAX_LENGTH_ESTIMATED,
          charactersAllowed: '.0-9',
        } as Partial<FunctionProperty>,
      },
      cellClass: 'ag-numeric-cell',
      cellStyle: { justifyContent: 'flex-end' },
      valueParser: (params: ValueParserParams) => toNumber(params.newValue),
      filter: ClientSideNumberFilter,
      floatingFilter: true,
    }),
    [field, getWbsItemType]
  )
  return property as BulkSheetProperty
}

export const useEstimatedStoryPoint = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.estimated.storypoint',
      }),
      hide: true,
      width: 120,
      editable: true,
      cellClass: 'ag-numeric-cell',
      cellStyle: { justifyContent: 'flex-end' },
      cellRenderer: NumberCellRenderer,
      cellRendererParams: {
        numberWithCommas: true,
        decimalPoints: 0,
      },
      cellEditorParams: {
        uiMeta: {
          propertyType: PropertyType.Number,
          maxLength: 4,
        } as Partial<FunctionProperty>,
      },
      valueParser: (params: ValueParserParams) => toInteger(params.newValue),
      filter: ClientSideNumberFilter,
      floatingFilter: true,
    }),
    [field]
  )
  return property
}

// Progress -----
export const useEstimatedProgressRate = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressRateCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.scheduledProgressRate',
      }),
      width: 90,
    }),
    [field]
  )
  return property
}

export const useProgressRate = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressRateCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.progressRate',
      }),
    }),
    [field]
  )
  return property
}

export const usePlannedValue = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.scheduledToBeCompleted',
      }),
    }),
    [field]
  )
  return property
}

export const useEarnedValue = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.progress.earnedvalue',
      }),
    }),
    [field]
  )
  return property
}

export const usePreceding = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.preceding',
      }),
    }),
    [field]
  )
  return property
}

export const useDelayed = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.delayed',
      }),
    }),
    [field]
  )
  return property
}

export const useRemaining = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...progressCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.remaining',
      }),
    }),
    [field]
  )
  return property
}

// Productivity -----
export const useActualHour = <TRow extends ListRow>({
  field,
  getWbsItemType,
  getCumulation,
  hasWbsItem,
}: {
  field: string
  getWbsItemType: (row: TRow | undefined) => WbsItemTypeVO | undefined
  getCumulation: (row: TRow | undefined) => ProjectPlanCumulation | undefined
  hasWbsItem: (row: TRow | undefined) => boolean
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.actualHour',
      }),
      cellRenderer: NumberCellRenderer,
      cellRendererParams: {
        numberWithCommas: true,
        decimalPoints: WORKLOAD_VALUE_DIGITS,
      },
      valueGetter: (params: ValueGetterParams<TRow>) => {
        const cumulation = getCumulation(params.data)
        if (!hasWbsItem(params.data) || !cumulation) return
        const hour = getWbsItemType(params.data)?.isTask()
          ? cumulation.actualHour
          : cumulation.sumActualHour
        return (
          hour / (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
        )
      },
      valueFormatter: (params: ValueFormatterParams<TRow>) =>
        floatValueFormatter(params, '', WORKLOAD_VALUE_DIGITS),
      cellStyle: (params: CellClassParams<TRow>) => {
        const numberStyle = { justifyContent: 'flex-end' }
        const cumulation = getCumulation(params.data)
        if (!hasWbsItem(params.data) || !cumulation) return numberStyle
        if (0 < cumulation.actualHour) {
          return {
            ...numberStyle,
            cursor: 'pointer',
            color: 'blue',
          }
        }
        return numberStyle
      },
      filter: ClientSideNumberFilter,
      floatingFilter: true,
    }),
    [field, getWbsItemType, getCumulation, hasWbsItem]
  )
  return property
}

export const useCostVariance = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.costVariance',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.CV' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.CV' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useCostPerformanceIndex = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmRateCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.costPerformanceIndex',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useScheduleVariance = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.scheduleVariance',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.SV' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.SV' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useSchedulePerformanceIndex = (
  field: string
): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmRateCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.schedulePerformanceIndex',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useEstimateToComplete = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.estimateToComplete',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useEstimateAtCompletion = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.estimateAtCompletion',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
        }),
      },
    }),
    [field]
  )
  return property
}

export const useVarianceAtCompletion = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      ...evmCellCommonColumnDef,
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.varianceAtCompletion',
      }),
      headerTooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
      headerComponentParams: {
        template: customHeaderTemplate({
          tooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
        }),
      },
    }),
    [field]
  )
  return property
}

// Term ------
const today = DateVO.now()
export const isStartDelayed = (
  status: WbsItemStatus | undefined,
  scheduledDate: DateTerm | undefined,
  actualDate: DateTerm | undefined
): boolean => {
  if (!status) return false
  const scheduledStart = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  const actualStart = actualDate?.startDate
    ? new DateVO(actualDate.startDate)
    : undefined
  return !!(
    scheduledStart &&
    status === WbsItemStatus.TODO &&
    ((!actualStart && today.isAfter(scheduledStart)) ||
      (actualStart && actualStart.isAfter(scheduledStart)))
  )
}

export const isEndDelayed = (
  status: WbsItemStatus | undefined,
  scheduledDate: DateTerm | undefined,
  actualDate: DateTerm | undefined
): boolean => {
  if (!status) return false
  const scheduledEnd = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  const actualEnd = actualDate?.endDate
    ? new DateVO(actualDate.endDate)
    : undefined
  return !!(
    scheduledEnd &&
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(status) &&
    ((!actualEnd && today.isAfter(scheduledEnd)) ||
      (actualEnd && actualEnd.isAfter(scheduledEnd)))
  )
}

export const endDateCellEditorParams = <TRow extends ListRow>(
  getTargetDateTerm: (row: TRow | undefined) => DateTerm | undefined
) => {
  return {
    getInitialValueOnCalendar: (params: IDateCellEditorParams) => {
      const startDate = getTargetDateTerm(params.data)?.startDate
      return startDate ? dateVoService.construct(startDate) : undefined
    },
  }
}

export const useSprint = <TRow extends ListRow>({
  field,
  optionKey = 'sprint',
  getWbsItemProjectUuid,
  getTeamUuid,
}: {
  field: string
  optionKey?: string
  getWbsItemProjectUuid: (row: TRow | undefined) => string | undefined
  getTeamUuid: (row: TRow | undefined) => string | undefined
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'wbsItem.property.sprint' }),
      hide: true,
      width: 120,
      editable: true,
      type: [ColumnType.autocomplete],
      cellRenderer: EntitySearchCellRenderer,
      cellEditor: EntitySearchCellEditor,
      cellEditorParams: {
        entity: optionKey,
        optionsFilter: (option: SprintDetail, data: TRow): boolean => {
          return (
            [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
              option.status
            ) &&
            option.projectUuid === getWbsItemProjectUuid(data) &&
            option.teamUuid === getTeamUuid(data)
          )
        },
      },
      suppressKeyboardEvent: params =>
        (!params.editing &&
          ['Delete', 'Backspace'].includes(params.event.key)) ||
        (params.editing && params.event.key === 'Enter'),
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        optionKey,
        valueGetter: ({ node, context }) => {
          const val = objects.getValue(node.data, field)
          return typeof val === 'string' ? undefined : val // Exclude unselected data
        },
        getValue: option => (option && option.uuid ? option.uuid : ''),
        getLabel: option =>
          `[${option.status}] (${option.teamName ?? ''}) ${option.name}`,
        sortValues: (_: any, options?: SprintDetail[]) => {
          if (!options) return options
          return options.sort((a, b) => {
            if (a.status === b.status) {
              return (a.teamName ?? '') > (b.teamName ?? '') ? 1 : -1
            }
            return (
              FilterSortOrder.indexOf(a.status) -
              FilterSortOrder.indexOf(b.status)
            )
          })
        },
      },
      comparator: (a: SprintDetail, b: SprintDetail) => {
        if (a?.name === b?.name) return 0
        return (a?.name ?? '').localeCompare(b?.name ?? '')
      },
    }),
    [field, optionKey, getWbsItemProjectUuid, getTeamUuid]
  )

  return property
}

export const useScheduledStartDate = <TRow extends ListRow>({
  field,
  getScheduledDate,
  setScheduledEndDate,
}: {
  field: string
  getScheduledDate: (row: TRow | undefined) => DateTerm | undefined
  setScheduledEndDate: (
    params: ValueSetterParams<TRow>,
    newValue: string,
    oldValue: string
  ) => void
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.scheduledDate.start',
      }),
      width: 125,
      editable: true,
      cellEditor: DateCellEditor,
      type: [ColumnType.date],
      valueSetter: (params: ValueSetterParams<TRow>) => {
        return commonValueSetter(params, (params: ValueSetterParams<TRow>) => {
          const end = getScheduledDate(params.data)?.endDate
          if (params.newValue && end) {
            const startDate = new DateVO(params.newValue)
            const endDate = new DateVO(end)
            if (startDate.isAfter(endDate)) {
              setScheduledEndDate(
                params,
                startDate.serialize(),
                endDate.serialize()
              )
            }
          }
        })
      },
      onCellValueChanged: (event: NewValueParams) => {
        const params = { force: true }
        if (event.node) params['rowNodes'] = [event.node]
        event.api?.refreshCells(params)
      },
      floatingFilter: true,
    }),
    [field, getScheduledDate, setScheduledEndDate]
  )
  return property
}

export const useScheduledEndDate = <TRow extends ListRow>({
  field,
  getScheduledDate,
  setScheduledStartDate,
}: {
  field: string
  getScheduledDate: (row: TRow | undefined) => DateTerm | undefined
  setScheduledStartDate: (
    params: ValueSetterParams<TRow>,
    newValue: string,
    oldValue: string
  ) => void
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.scheduledDate.end',
      }),
      width: 125,
      editable: true,
      cellEditor: DateCellEditor,
      type: [ColumnType.date],
      valueSetter: (params: ValueSetterParams<TRow>) => {
        return commonValueSetter(params, (params: ValueSetterParams<TRow>) => {
          const start = getScheduledDate(params.data)?.startDate
          if (params.newValue && start) {
            const startDate = new DateVO(start)
            const endDate = new DateVO(params.newValue)
            if (startDate.isAfter(endDate)) {
              setScheduledStartDate(
                params,
                endDate.serialize(),
                startDate.serialize()
              )
            }
          }
        })
      },
      floatingFilter: true,
      onCellValueChanged: (event: NewValueParams) => {
        const params = { force: true }
        if (event.node) params['rowNodes'] = [event.node]
        event.api?.refreshCells(params)
      },
    }),
    [field, getScheduledDate, setScheduledStartDate]
  )
  return property
}

export const useActualStartDate = <TRow extends ListRow>({
  field,
  getWbsItemStatus,
  getScheduledDate,
  getActualDate,
  setActualEndDate,
}: {
  field: string
  getWbsItemStatus: (row: TRow | undefined) => WbsItemStatus | undefined
  getScheduledDate: (row: TRow | undefined) => DateTerm | undefined
  getActualDate: (row: TRow | undefined) => DateTerm | undefined
  setActualEndDate: (
    params: ValueSetterParams<TRow>,
    newValue: string,
    oldValue: string
  ) => void
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'wbsItem.property.actualDate.start',
      }),
      width: 125,
      editable: true,
      cellEditor: DateCellEditor,
      type: [ColumnType.date],
      valueSetter: (params: ValueSetterParams<TRow>) => {
        return commonValueSetter(params, (params: ValueSetterParams<TRow>) => {
          const end = getActualDate(params.data)?.endDate
          if (params.newValue && end) {
            const startDate = new DateVO(params.newValue)
            const endDate = new DateVO(end)
            if (startDate.isAfter(endDate)) {
              setActualEndDate(
                params,
                startDate.serialize(),
                endDate.serialize()
              )
            }
          }
        })
      },
      valueGetter: (params: ValueGetterParams<TRow>) => {
        return getActualDate(params.data)?.startDate ?? ''
      },
      cellRendererParams: {
        tooltip: (params: ICellRendererParams<TRow>): string | undefined => {
          return isStartDelayed(
            getWbsItemStatus(params.data),
            getScheduledDate(params.data),
            getActualDate(params.data)
          )
            ? intl.formatMessage({ id: 'wbs.start.delayed' })
            : undefined
        },
      },
      cellStyle: (params: CellClassParams<TRow>): CellStyle => {
        const style = { justifyContent: 'flex-end' }
        if (
          isStartDelayed(
            getWbsItemStatus(params.data),
            getScheduledDate(params.data),
            getActualDate(params.data)
          )
        ) {
          return { ...style, backgroundColor: BackgroundColor.ALERT }
        }
        return style
      },
      floatingFilter: true,
      onCellValueChanged: (event: NewValueParams) => {
        const params = { force: true }
        if (event.node) params['rowNodes'] = [event.node]
        event.api?.refreshCells(params)
      },
    }),
    [field, getWbsItemStatus, getScheduledDate, getActualDate, setActualEndDate]
  )
  return property
}

export const useActualEndDate = <TRow extends ListRow>({
  field,
  getWbsItemStatus,
  getScheduledDate,
  getActualDate,
  setActualStartDate,
}: {
  field: string
  getWbsItemStatus: (row: TRow | undefined) => WbsItemStatus | undefined
  getScheduledDate: (row: TRow | undefined) => DateTerm | undefined
  getActualDate: (row: TRow | undefined) => DateTerm | undefined
  setActualStartDate: (
    params: ValueSetterParams<TRow>,
    newValue: string,
    oldValue: string
  ) => void
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({
        id: 'projectPlan.actualDate.end',
      }),
      width: 125,
      editable: true,
      cellEditor: DateCellEditor,
      type: [ColumnType.date],
      cellEditorParams: endDateCellEditorParams((row: TRow | undefined) =>
        getActualDate(row)
      ),
      valueSetter: (params: ValueSetterParams<TRow>) => {
        return commonValueSetter(params, (params: ValueSetterParams<TRow>) => {
          const start = getActualDate(params.data)?.startDate
          if (params.newValue && start) {
            const startDate = new DateVO(start)
            const endDate = new DateVO(params.newValue)
            if (startDate.isAfter(endDate)) {
              setActualStartDate(
                params,
                endDate.serialize(),
                startDate.serialize()
              )
            }
          }
        })
      },
      valueGetter: (params: ValueGetterParams<TRow>) => {
        return getActualDate(params.data)?.endDate ?? ''
      },
      cellRendererParams: {
        tooltip: (params: ICellRendererParams<TRow>): string | undefined => {
          return isEndDelayed(
            getWbsItemStatus(params.data),
            getScheduledDate(params.data),
            getActualDate(params.data)
          )
            ? intl.formatMessage({ id: 'wbs.end.delayed' })
            : undefined
        },
      },
      cellStyle: (params: CellClassParams<TRow>): CellStyle => {
        const style = { justifyContent: 'flex-end' }
        if (
          isEndDelayed(
            getWbsItemStatus(params.data),
            getScheduledDate(params.data),
            getActualDate(params.data)
          )
        ) {
          return { ...style, backgroundColor: BackgroundColor.ALERT }
        }
        return style
      },
      floatingFilter: true,
      onCellValueChanged: (event: NewValueParams) => {
        const params = { force: true }
        if (event.node) params['rowNodes'] = [event.node]
        event.api?.refreshCells(params)
      },
    }),
    [
      field,
      getWbsItemStatus,
      getScheduledDate,
      getActualDate,
      setActualStartDate,
    ]
  )
  return property
}

// Ptivate variables and functions -----
const WORKLOAD_VALUE_DIGITS: number = 2
const MAX_LENGTH_ESTIMATED: number = 6
const PROGRESS_WORKLOAD_VALUE_DIGITS: number = 1
const PROGRESS_COUNT_VALUE_DIGITS: number = 0
const EVM_VALUE_DIGITS: number = 1

const WBS_ITEM_STATUS_ORDER: WbsItemStatus[] = [
  WbsItemStatus.TODO,
  WbsItemStatus.DOING,
  WbsItemStatus.REVIEW,
  WbsItemStatus.DONE,
  WbsItemStatus.DISCARD,
]

const sortWbsItemType = (
  valueA: WbsItemTypeCellValue | TicketTypeCellValue,
  valueB: WbsItemTypeCellValue | TicketTypeCellValue
) => {
  if (valueA?.typeIndex === valueB?.typeIndex) return 0
  if (valueA?.typeIndex !== undefined || valueB?.typeIndex !== undefined) {
    return (valueA?.typeIndex ?? 0) - (valueB?.typeIndex ?? 0)
  } else {
    if (valueA?.wbsItemType?.name === valueB?.wbsItemType?.name) return 0
    return (valueA?.wbsItemType?.name ?? '').localeCompare(
      valueB?.wbsItemType?.name ?? ''
    )
  }
}

const wbsItemStatusComparator = (a: WbsItemStatus, b: WbsItemStatus) =>
  WBS_ITEM_STATUS_ORDER.findIndex(s => s === a) -
  WBS_ITEM_STATUS_ORDER.findIndex(s => s === b)

interface StatusFilterParams {
  status: WbsItemStatus
  backgroundColor: string
}

const customEnumValueSetter = (params: ValueSetterParams) => {
  return commonValueSetter(params, (params: ValueSetterParams) => {
    const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
    const options: CustomEnumValue[] = params.context[customEnumCode]

    const customEnumValue = options.find(o => o.value === params.newValue)
    const bidirection = customEnumValue?.combinations?.find(
      v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
    )
    if (bidirection && combinedValuePath) {
      objects.setValue(
        params.data,
        combinedValuePath?.(bidirection.combinedEnumCode),
        bidirection.combinedValues?.[0]?.value
      )
    }
  })
}

const sortTag = (valueA: TagForWbsItem[], valueB: TagForWbsItem[]) => {
  //　don't want to get tags every time
  const projectUuid = store.getState().project.selected
  if (!projectUuid) return 0
  const tags = store.getState().tag[projectUuid]
  if (!tags) return 0
  const minIndex = Math.min(valueA?.length || 0, valueB?.length || 0)
  for (let i = 0; i < minIndex; i++) {
    const diff =
      tags.findIndex(e => e.uuid === valueA[i]?.uuid) -
      tags.findIndex(e => e.uuid === valueB[i]?.uuid)
    if (diff !== 0) return diff
  }
  return (valueA?.length || 0) - (valueB?.length || 0)
}

const useProjectMember = <TRow extends ListRow>({
  field,
  optionKey = 'member',
  getWbsItemProjectUuid,
}: {
  field: string
  optionKey: string
  getWbsItemProjectUuid?: (row: TRow) => string | undefined
}) => {
  const property = useMemo(
    () => ({
      field,
      width: 120,
      editable: true,
      type: [ColumnType.autocomplete],
      cellRenderer: EntitySearchCellRenderer,
      cellEditor: EntitySearchCellEditor,
      cellEditorParams: {
        entity: optionKey,
        optionsFilter: (option: ProjectMemberProps, data: TRow): boolean => {
          const projectUuid =
            getWbsItemProjectUuid && getWbsItemProjectUuid(data)
          return (
            !projectUuid ||
            !option.projectUuid ||
            option.projectUuid === projectUuid
          )
        },
      },
      suppressKeyboardEvent: params =>
        (!params.editing &&
          ['Delete', 'Backspace'].includes(params.event.key)) ||
        (params.editing && params.event.key === 'Enter'),
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: ({ node, context }) => {
          const val = objects.getValue(node.data, field)
          return typeof val === 'string' ? undefined : val // Exclude unselected data
        },
        getValue: (v?: ProjectMemberProps) => v?.uuid,
        getLabel: (v?: ProjectMemberProps) => v?.name,
      },
      comparator: (valueA: ProjectMemberProps, valueB: ProjectMemberProps) =>
        (valueA?.name ?? '').localeCompare(valueB?.name ?? ''),
    }),
    [field, optionKey, getWbsItemProjectUuid]
  )
  return property
}

const sortWatchers = (
  valueA: ProjectMemberProps[],
  valueB: ProjectMemberProps[]
) => {
  const minIndex = Math.min(valueA?.length || 0, valueB?.length || 0)
  for (let i = 0; i < minIndex; i++) {
    const diff = (valueA[i]?.name ?? '').localeCompare(valueB[i]?.name ?? '')
    if (diff !== 0) return diff
  }
  return (valueA?.length || 0) - (valueB?.length || 0)
}

const progressCellCommonColumnDef = {
  width: 80,
  editable: false,
  hide: true,
  valueFormatter: (params: ValueFormatterParams) => {
    const digit =
      !params.context ||
      params.context?.aggregateField === AggregateField.WBS_ITEM_WORKLOAD
        ? PROGRESS_WORKLOAD_VALUE_DIGITS
        : PROGRESS_COUNT_VALUE_DIGITS
    return floatValueFormatter(params, '', digit)
  },
  cellStyle: () => {
    return {
      color: TextColor.DARK_BLACK,
      justifyContent: 'flex-end',
    }
  },
  filter: ClientSideNumberFilter,
  comparator: (valueA: number | string, valueB: number | string) => {
    const numA = typeof valueA === 'string' ? NaN : Number(valueA)
    const numB = typeof valueB === 'string' ? NaN : Number(valueB)
    if (!isFinite(numA) && !isFinite(numB)) return 0
    else if (!isFinite(numA)) return -1
    else if (!isFinite(numB)) return 1

    return numA - numB
  },
}

const progressRateCellCommonColumnDef = {
  ...progressCellCommonColumnDef,
  valueFormatter: (params: ValueFormatterParams) => {
    const numValue = parseFloat(params.value)
    if (isNaN(numValue)) return '-'
    return `${(numValue * 100).toFixed(PROGRESS_WORKLOAD_VALUE_DIGITS)}%`
  },
}

const evmCellCommonColumnDef = {
  ...progressCellCommonColumnDef,
  hide: true,
  valueFormatter: (params: ValueFormatterParams) =>
    floatValueFormatter(params, '-', EVM_VALUE_DIGITS),
}

const evmRateCellCommonColumnDef = {
  ...progressCellCommonColumnDef,
  hide: true,
  valueFormatter: (params: ValueFormatterParams) => {
    const numValue = parseFloat(params.value)
    if (isNaN(numValue)) return '-'
    return `${(numValue * 100).toFixed(EVM_VALUE_DIGITS)}%`
  },
}
