import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import WbsItemApi, {
  createRowByResponse as createWbsItemDataByResponse,
  WbsItemBatchDeltaRequest,
  WbsItemDeltaInput,
  WbsItemRow,
  WbsItemSearchDetail,
  WbsItemUpdateBatchDeltaRequest,
} from '../../../../lib/functions/wbsItem'
import { useWbsItemSearchConditionApiRequestTransformService } from '../../../../services/transform-service/wbsItemSearchConditionApiRequestTransformService'
import store from '../../../../store'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import { getTypeIndex } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { WbsItemSearchRow } from '../wbsItemSearch'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { hasDiffDateTerm } from '../../../../utils/date'
import { ProjectMemberProps } from '../../../../lib/functions/projectMember'
import { isTagColumnEdited } from '../../../../lib/functions/tag'
import { successDummyResponse } from '../../../../lib/commons/api'
import {
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../../lib/functions/sprintProductItem'
import SprintBacklogApi, {
  UpdateBatchSprintBacklogProps,
} from '../../../../lib/functions/sprintBacklog'

export interface Props {
  projectUuid: string
  searchCondition: WbsItemSearchConditionVO
}

type FetchWbsItemResponase = {
  total: number
  hit: number
  data: WbsItemSearchDetail[]
  offset: number
  limit: number
}

export const useWbsItemSearchData = ({
  projectUuid,
  searchCondition,
}: Props) => {
  const [totalDataSize, setTotalDataSize] = useState<number>(0)
  const [data, setDataInternal] = useState<WbsItemSearchRow[]>([])
  const originalData = useRef<Map<string, WbsItemSearchRow>>(new Map())

  // Fetch ----------
  const getTicketTypeIndex = useCallback((baseTypeDto: WbsItemTypeVO) => {
    let rootType = baseTypeDto
    while (!!rootType.child) {
      rootType = rootType.child
    }
    return getTypeIndex(rootType)
  }, [])

  const createRowByResponse = useCallback(
    (responseData: WbsItemSearchDetail) => {
      const wbsItemRow: WbsItemRow = createWbsItemDataByResponse(
        responseData,
        responseData.cumulation
      )
      wbsItemRow.watchers = wbsItemRow.watchers?.sort(
        (valueA: ProjectMemberProps, valueB: ProjectMemberProps) => {
          return (valueA?.name ?? '').localeCompare(valueB?.name ?? '')
        }
      )

      return {
        uuid: wbsItemRow.uuid,
        lockVersion: wbsItemRow.lockVersion,
        wbsItem: wbsItemRow,
        path: responseData.path,
        cumulation: responseData.cumulation,
        commentSummary: responseData.commentSummary,
        deliverableAttachmentSummary: responseData.deliverableAttachmentSummary,
        typeIndex: getTypeIndex(new WbsItemTypeVO(responseData.baseTypeDto)),
        ticketTypeIndex: getTicketTypeIndex(responseData.baseTypeDto),
        parentWbsItem: {
          ...responseData.parentWbsItem,
          wbsItemType: responseData.parentWbsItem?.typeDto
            ? new WbsItemTypeVO(responseData.parentWbsItem.typeDto)
            : undefined,
        },
        extensions: responseData.extensions,
        revision: wbsItemRow.revision,
        createdAt: wbsItemRow.createdAt,
        createdBy: wbsItemRow.createdBy,
        updatedAt: wbsItemRow.updatedAt,
        updatedBy: wbsItemRow.updatedBy,
        treeValue: [wbsItemRow.uuid],
      } as WbsItemSearchRow
    },
    [getTicketTypeIndex]
  )

  const searchConditionApiRequestTransformer =
    useWbsItemSearchConditionApiRequestTransformService(projectUuid)

  const searchConditionForApi = useMemo(
    () => searchConditionApiRequestTransformer.toApiRequest(searchCondition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectUuid, searchCondition]
  )

  const fetch = useCallback(async () => {
    const response = await WbsItemApi.searchWbsItem(searchConditionForApi)
    const fetchData: FetchWbsItemResponase = response.json
    setTotalDataSize(fetchData.total)
    return fetchData.data.map(d => createRowByResponse(d))
  }, [searchConditionForApi, createRowByResponse])

  const refresh = useCallback(async () => {
    const rows: WbsItemSearchRow[] = await fetch()
    setDataInternal(rows)
    originalData.current.clear()
    rows
      .filter(row => !!row.wbsItem)
      .forEach(row => {
        originalData.current.set(row.uuid, _.cloneDeep(row))
      })
    store.dispatch(doNotRequireSave())
  }, [fetch])

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConditionForApi])

  const refreshSingleRow = useCallback(
    async (wbsItemUuid: string | undefined) => {
      if (!wbsItemUuid) return
      const targetData: WbsItemSearchRow | undefined = data.find(
        d => d?.wbsItem?.uuid === wbsItemUuid
      )
      if (!targetData) return
      const response = await WbsItemApi.findByUuid(projectUuid, wbsItemUuid)
      const newRow = createRowByResponse(response.json)
      const newData: WbsItemSearchRow[] = data.map((row: WbsItemSearchRow) => {
        if (row.uuid !== newRow.uuid) return row
        return newRow
      })
      originalData.current.set(newRow.uuid, _.cloneDeep(newRow))
      setDataInternal(newData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  // Save ----------
  const watcherToString = useCallback(
    (watcher: ProjectMemberProps[] | undefined) =>
      watcher
        ?.map(v => v.uuid)
        .sort()
        .join(),
    []
  )

  const rowToWbsItemDeltaInput = useCallback(
    (
      row: WbsItemSearchRow | undefined,
      originalDataMap: Map<string, WbsItemSearchRow>
    ) => {
      if (!row) return undefined
      const original: WbsItemRow | undefined = originalDataMap.get(
        row.uuid
      )?.wbsItem
      const wbsItem: WbsItemRow = row.wbsItem

      return {
        uuid: row.wbsItem.uuid,
        type: row.wbsItem.type,
        typeUuid: createDelta(
          original?.wbsItemType?.uuid,
          wbsItem?.wbsItemType?.uuid
        ),
        status: createDelta(original?.status, wbsItem?.status),
        substatus: createDelta(original?.substatus, wbsItem?.substatus),
        displayName: createDelta(original?.displayName, wbsItem?.displayName),
        description: createDelta(original?.description, wbsItem?.description),
        teamUuid: createDelta(original?.team?.uuid, wbsItem.team?.uuid),
        accountableUuid: createDelta(
          original?.accountable?.uuid,
          wbsItem?.accountable?.uuid
        ),
        responsibleUuid: createDelta(
          original?.responsible?.uuid,
          wbsItem?.responsible?.uuid
        ),
        assigneeUuid: createDelta(
          original?.assignee?.uuid,
          wbsItem?.assignee?.uuid
        ),
        difficulty: createDelta(original?.difficulty, wbsItem?.difficulty),
        estimatedStoryPoint: createDelta(
          original?.estimatedStoryPoint,
          wbsItem?.estimatedStoryPoint
        ),
        estimatedHour: createDelta(
          original?.estimatedWorkload?.hour,
          wbsItem?.estimatedWorkload?.hour
        ),
        priority: createDelta(original?.priority, wbsItem?.priority),
        scheduledDate: hasDiffDateTerm(
          original?.scheduledDate,
          wbsItem.scheduledDate
        )
          ? {
              oldValue: original?.scheduledDate,
              newValue: wbsItem?.scheduledDate,
            }
          : undefined,
        actualDate: hasDiffDateTerm(original?.actualDate, wbsItem.actualDate)
          ? {
              oldValue: original?.actualDate,
              newValue: wbsItem?.actualDate,
            }
          : undefined,
        // TODO:
        //  extensions: {
        //    uuid: string
        //    oldValue: any
        //    newValue: any
        //  }[]
        sprintUuid: createDelta(original?.sprint?.uuid, wbsItem?.sprint?.uuid),
      } as WbsItemDeltaInput
    },
    []
  )

  const updateWbsItem = useCallback(
    async (
      projectUuid: string,
      data: WbsItemSearchRow[],
      originalDataMap: Map<string, WbsItemSearchRow>
    ) => {
      const editedRows = data.filter(
        (row: WbsItemSearchRow) => row.edited && originalDataMap.has(row.uuid)
      )

      const edited = editedRows
        .map((row: WbsItemSearchRow) =>
          rowToWbsItemDeltaInput(row, originalDataMap)
        )
        .filter(v => !!v)

      const watchers = editedRows
        .filter((row: WbsItemSearchRow) => {
          const original =
            originalDataMap.get(row.uuid)?.wbsItem?.watchers ?? []
          const watchers = row.wbsItem?.watchers ?? []
          return watcherToString(original) !== watcherToString(watchers)
        })
        .map((row: WbsItemSearchRow) => ({
          wbsItemUuid: row.wbsItem?.uuid,
          userUuids: row.wbsItem?.watchers?.map(watcher => watcher.uuid),
        }))

      const tags = editedRows
        .filter((row: WbsItemSearchRow) => {
          const original = originalDataMap.get(row.uuid)?.wbsItem?.tags ?? []
          const watchers = row.wbsItem?.tags ?? []
          return isTagColumnEdited(original, watchers)
        })
        .map((row: WbsItemSearchRow) => ({
          wbsItemUuid: row.wbsItem?.uuid,
          tagUuids: row.wbsItem?.tags?.map(tag => tag.uuid),
        }))

      const response = await WbsItemApi.updateBatchDelta({
        wbsItems: {
          projectUuid,
          added: [],
          edited,
          deleted: [],
        } as WbsItemUpdateBatchDeltaRequest,
        watchers,
        tags,
      } as WbsItemBatchDeltaRequest)

      return response
    },
    [watcherToString, rowToWbsItemDeltaInput]
  )

  const updateSprintProduct = useCallback(
    async (
      data: WbsItemSearchRow[],
      originalDataMap: Map<string, WbsItemSearchRow>,
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
    ) => {
      const deliverables = data.filter(
        (row: WbsItemSearchRow) =>
          row.edited &&
          !!row.wbsItem?.uuid &&
          row.wbsItem?.wbsItemType?.isDeliverable()
      )
      if (_.isEmpty(deliverables)) return successDummyResponse
      const sprintProductRequest: UpdateSprintProductItemProps = {
        added: [],
        deleted: [],
      }
      deliverables.forEach((row: WbsItemSearchRow) => {
        const wbsItem = row.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = originalDataMap.get(row.uuid)?.wbsItem.sprint
        if (
          !!sprint?.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintProductRequest.added.push({
            deliverableUuid: wbsItem.uuid!,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid!] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (row.edited && !sprint?.uuid && !!originalSprint) {
          sprintProductRequest.deleted.push({
            deliverableUuid: wbsItem.uuid!,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid!] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintProductRequest.added) &&
        _.isEmpty(sprintProductRequest.deleted)
      ) {
        return successDummyResponse
      }
      const response = await updateSprintProductItems(sprintProductRequest)
      return response
    },
    []
  )

  const updateSprintBacklog = useCallback(
    async (
      data: WbsItemSearchRow[],
      originalDataMap: Map<string, WbsItemSearchRow>,
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
    ) => {
      const tasks = data.filter(
        (row: WbsItemSearchRow) =>
          row.edited &&
          !!row.wbsItem?.uuid &&
          row.wbsItem?.wbsItemType?.isTask()
      )
      if (_.isEmpty(tasks)) return successDummyResponse
      const sprintBacklogRequest: UpdateBatchSprintBacklogProps = {
        sprintBacklogItems: [],
        sprintPlans: {
          added: [],
          deleted: [],
        },
      }
      tasks.forEach(row => {
        const wbsItem = row.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = originalDataMap.get(row.uuid)?.wbsItem.sprint
        if (
          !!sprint?.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintBacklogRequest.sprintPlans.added.push({
            taskUuid: wbsItem.uuid!,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid!] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (row.edited && !sprint?.uuid && !!originalSprint) {
          sprintBacklogRequest.sprintPlans.deleted.push({
            taskUuid: wbsItem.uuid!,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid!] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintBacklogRequest.sprintPlans.added) &&
        _.isEmpty(sprintBacklogRequest.sprintPlans.deleted)
      ) {
        return successDummyResponse
      }
      return SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)
    },
    []
  )

  const save = useCallback(async () => {
    const updateWbsItemResponse = await updateWbsItem(
      projectUuid,
      data,
      originalData.current
    )
    const wbsItemLockVersionMap = updateWbsItemResponse?.json.edited
      ? Object.fromEntries(
          [...updateWbsItemResponse?.json.edited].map(v => [
            v.uuid,
            v.lockversion,
          ])
        )
      : {}
    const updateSprintProductResponse = await updateSprintProduct(
      data,
      originalData.current,
      wbsItemLockVersionMap
    )
    const updateSprintBacklogResponse = await updateSprintBacklog(
      data,
      originalData.current,
      wbsItemLockVersionMap
    )
    return [
      updateWbsItemResponse,
      updateSprintProductResponse,
      updateSprintBacklogResponse,
    ]
  }, [
    projectUuid,
    data,
    updateWbsItem,
    updateSprintProduct,
    updateSprintBacklog,
  ])

  const generateWbsItemDeltaRequest = useCallback(
    (row: WbsItemSearchRow | undefined): WbsItemDeltaInput | undefined =>
      rowToWbsItemDeltaInput(row, originalData.current),
    [originalData, rowToWbsItemDeltaInput]
  )

  return {
    totalDataSize,
    data,
    refresh,
    refreshSingleRow,
    save,
    generateWbsItemDeltaRequest,
  }
}
