import { useCallback, useMemo } from 'react'
import {
  CellClickedEvent,
  ColDef,
  RowNode,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { ProjectPlanCumulation } from '../../../../lib/functions/projectPlan'
import { WbsItemRow } from '../../../../lib/functions/wbsItem'
import Workload, { WorkloadUnit } from '../../../../lib/functions/workload'
import store from '../../../../store'
import DateVO from '../../../../vo/DateVO'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { FlagxsColumnDef } from '../../../containers/BulkSheetView/gridOptions/extension'
import { ColumnType, defaultColDef } from '../../../containers/commons/AgGrid'
import {
  useCreatedAt,
  useCreatedBy,
  useRevision,
  useUpdatedAt,
  useUpdatedBy,
} from '../../../page-properties/bulksheet-properties/changeLog'
import {
  useAccountable,
  useActualEndDate,
  useActualStartDate,
  useAssignee,
  useAttachments,
  useCode,
  useLatestComment,
  useCommentSummaryAction,
  useDescription,
  useDifficulty,
  useDisplayName,
  useEstimatedWorkLoadDeliverable,
  useParentWbsItem,
  usePath,
  usePriority,
  useResponsible,
  useScheduledEndDate,
  useScheduledStartDate,
  useSprint,
  useStatus,
  useSubstatus,
  useTags,
  useTeam,
  useTicketType,
  useType,
  useWatchers,
  useEstimatedWorkLoadTask,
  useEstimatedStoryPoint,
  useUuid,
  useEstimatedProgressRate,
  useProgressRate,
  usePlannedValue,
  useEarnedValue,
  usePreceding,
  useDelayed,
  useRemaining,
  useActualHour,
  useCostVariance,
  useScheduleVariance,
  useSchedulePerformanceIndex,
  useCostPerformanceIndex,
  useEstimateToComplete,
  useEstimateAtCompletion,
  useVarianceAtCompletion,
} from '../../../page-properties/bulksheet-properties/wbsItem'
import {
  commonValueSetter,
  defaultValueSetter,
} from '../../../page-properties/bulksheet-properties'
import { WbsItemSearchRow } from '../wbsItemSearch'

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'wbsItem.type'
    case 'TICKET_TYPE':
      return 'wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'wbsItem.substatus'
  }
  return undefined
}

/**
 * WbsItemSearch Common functions
 */
const isDone = (data: WbsItemSearchRow | undefined) =>
  data?.wbsItem?.status === WbsItemStatus.DONE

const isDiscard = (data: WbsItemSearchRow | undefined) =>
  data?.wbsItem?.status === WbsItemStatus.DISCARD

const isScheduledToBeDone = (data: WbsItemSearchRow | undefined) => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  return (
    wbsItem?.scheduledDate &&
    new DateVO(wbsItem?.scheduledDate.endDate).isSameOrBefore(DateVO.now())
  )
}

const isPreceding = (data: WbsItemSearchRow | undefined) => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  return (
    wbsItem?.scheduledDate &&
    new DateVO(wbsItem?.scheduledDate.endDate).isAfter(DateVO.now()) &&
    wbsItem?.status === WbsItemStatus.DONE
  )
}
const formatWorkload = (value: number | undefined, denominator: number = 1) =>
  Number((value || 0) / denominator)

const getDeliverableEstimatedHour = (
  data: WbsItemSearchRow | undefined
): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    return 0
  }
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return wbsItem?.estimatedWorkload?.hour || 0
  }
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  return cumulation
    ? cumulation.sumDeliverableEstimatedHour -
        cumulation.sumDeliverableEstimatedHourDiscard
    : 0
}

const getTaskEstimatedHour = (data: WbsItemSearchRow | undefined): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    return wbsItem?.estimatedWorkload?.hour || 0
  }
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return (
      cumulation.sumTaskEstimatedHourOfDirectChildren -
      cumulation.sumTaskEstimatedHourDiscardOfDirectChildren
    )
  }
  return (
    cumulation.sumTaskEstimatedHour - cumulation.sumTaskEstimatedHourDiscard
  )
}

const getTaskEarnedValue = (data: WbsItemSearchRow | undefined): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    return isDone(data) ? wbsItem?.estimatedWorkload?.hour || 0 : 0
  }

  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return cumulation.sumTaskEstimatedHourDoneOfDirectChildren
  }
  return cumulation.sumTaskEstimatedHourDone
}

const getRate = (numerator: number, denominator?: number) => {
  if (!denominator) return '-'
  return numerator / denominator
}

const getActualHour = (data: WbsItemSearchRow): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isTask()) {
    return cumulation.actualHour
  } else if (wbsItem?.wbsItemType?.isDeliverable()) {
    return cumulation.sumActualHourOfDirectChildren
  }
  return cumulation.sumActualHour
}

const getTaskPlannedValue = (data: WbsItemSearchRow | undefined): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    if (isScheduledToBeDone(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return cumulation.sumTaskPlannedHourOfDirectChildren
  }
  return cumulation.sumTaskToBeCompleted
}

const getTaskCostPerformanceIndex = (data: WbsItemSearchRow): number => {
  const ev = getTaskEarnedValue(data)
  const ac = getActualHour(data)
  return ev / ac
}

const getTaskEstimateToComplete = (data: WbsItemSearchRow): number => {
  const bac = getTaskEstimatedHour(data)
  const ev = getTaskEarnedValue(data)

  const cpi = getTaskCostPerformanceIndex(data)
  return (bac - ev) / cpi
}
const getTaskEstimateAtCompletion = (data: WbsItemSearchRow): number => {
  const ac = getActualHour(data)
  const etc = getTaskEstimateToComplete(data)
  return ac + etc
}

const formatEvm = (value: number | undefined, hoursPerUnit: number) =>
  (value || 0) / hoursPerUnit

const getPrecedingTaskWorkload = (
  data: WbsItemSearchRow | undefined
): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    if (isPreceding(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return cumulation.sumPrecedingTaskEstimatedHourOfDirectChildren
  }
  return cumulation.sumTaskEndPreceding
}

const getDelayedTaskWorkload = (data: WbsItemSearchRow | undefined): number => {
  const wbsItem: WbsItemRow | undefined = data?.wbsItem
  if (wbsItem?.wbsItemType?.isTask()) {
    if (isScheduledToBeDone(data) && wbsItem?.status !== WbsItemStatus.DONE) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  const cumulation: ProjectPlanCumulation | undefined = data?.cumulation
  if (!cumulation) return 0
  if (wbsItem?.wbsItemType?.isDeliverable()) {
    return cumulation.sumDelayedTaskEstimatedHourOfDirectChildren
  }
  return cumulation.sumTaskEndDelayed
}

const estimatedWorkLoadValueSetter = (
  params: ValueSetterParams<WbsItemSearchRow>
) => {
  return commonValueSetter(
    params,
    (params: ValueSetterParams<WbsItemSearchRow>) => {
      const unit = params.context.workloadUnit
      const { dailyWorkHours, monthlyWorkDays } =
        store.getState().tenant.organization!
      let workload: Workload
      switch (unit) {
        case WorkloadUnit.MONTH:
          workload = Workload.from({
            month: Number(params.newValue),
            standard: { dailyWorkHours, monthlyWorkDays },
          })
          break
        case WorkloadUnit.DAY:
          workload = Workload.from({
            day: Number(params.newValue),
            standard: { dailyWorkHours, monthlyWorkDays },
          })
          break
        case WorkloadUnit.HOUR:
          workload = Workload.from({
            hour: Number(params.newValue),
            standard: { dailyWorkHours, monthlyWorkDays },
          })
          break
        default:
          workload = new Workload(0, 0, 0, {
            dailyWorkHours,
            monthlyWorkDays,
          })
      }
      params.data.wbsItem.estimatedWorkload = workload
    }
  )
}

/*
 * Default Column Def
 */
export const DEFAULT_COLUMN_DEF: ColDef = {
  ...defaultColDef({ editable: false, sortable: true }),
  cellRenderer: DefaultCellRenderer,
  valueSetter: defaultValueSetter,
  suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
    // When editing in Japanese, any keyboard event should be suppressed.
    if (params.editing && params.event.isComposing) {
      return true
    }
    // Prevent editing cell for DELETE or BACKSPACE
    return !params.editing && ['Delete', 'Backspace'].includes(params.event.key)
  },
  cellClassRules: {
    'grid-edited-cell': params => {
      const { colDef, data, value } = params
      const field = colDef.field ?? colDef.colId
      if (
        !data.editedData ||
        !field ||
        !colDef.editable ||
        (typeof colDef.editable === 'function' && !colDef.editable(params))
      ) {
        return false
      }
      return (
        data.editedData.hasOwnProperty(field) &&
        value !== data.editedData[field]
      )
    },
  },
}

/*
 * WbsItemSearch Column Def
 */
export interface PropsForGetColumnDefs {
  projectUuid: string
  selectColumnOptionMap: {
    [key: string]: any
  }
  onClickedStatusCell?: (
    target: EventTarget | undefined,
    row: WbsItemSearchRow | undefined
  ) => void
  onClickedActualHourCell?: (row: WbsItemSearchRow | undefined) => void
  reloadSingleRow?: ((uuid: string) => void) | undefined
}

export const useColumnDefs = ({
  projectUuid,
  selectColumnOptionMap,
  onClickedStatusCell,
  onClickedActualHourCell,
  reloadSingleRow,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const uuidCol = useUuid()

  const getWbsItemUuidFromNode = useCallback(
    (node: RowNode<WbsItemSearchRow>) => node?.data?.wbsItem?.uuid,
    []
  )
  const getWbsItem = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem,
    []
  )
  const hasWbsItem = useCallback(
    (row: WbsItemSearchRow | undefined) => !!row?.wbsItem?.uuid,
    []
  )
  const getWbsItemType = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.wbsItemType,
    []
  )
  const getBaseWbsItemType = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.baseWbsItemType,
    []
  )
  const getTeamUuid = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.team?.uuid,
    []
  )
  const getScheduledDate = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.scheduledDate,
    []
  )
  const getActualDate = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.actualDate,
    []
  )
  const getWbsItemStatus = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.status,
    []
  )
  const getWbsItemProjectUuid = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.wbsItem?.projectUuid,
    []
  )

  const getCumulation = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.cumulation,
    []
  )
  const getCommentSummary = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.commentSummary,
    []
  )
  const getAttachmentSummary = useCallback(
    (row: WbsItemSearchRow) => row?.deliverableAttachmentSummary,
    []
  )
  const getTypeIndex = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.typeIndex,
    []
  )
  const getTicketTypeIndex = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.ticketTypeIndex,
    []
  )
  const getParentWbsItem = useCallback(
    (row: WbsItemSearchRow | undefined) => row?.parentWbsItem,
    []
  )

  // Basic information group column common def
  const codeCol = useCode('wbsItem.code')
  const typeCol = useType({
    field: 'wbsItem.type',
    getBaseWbsItemType,
    getTypeIndex,
  })
  const ticketTypeCol = useTicketType({
    field: 'wbsItem.ticketType',
    getBaseWbsItemType,
    getTypeIndex: getTicketTypeIndex,
  })
  const statusCol = useStatus({
    field: 'wbsItem.status',
    subStatusField: 'wbsItem.substatus',
    statusCombinedValuePath,
  })
  const substatusCol = useSubstatus({
    field: 'wbsItem.substatus',
    statusCombinedValuePath,
  })
  const actionCol = useCommentSummaryAction({
    field: 'action',
    getCommentSummary,
    getWbsItem,
  })
  const attachmentsCol = useAttachments({
    field: 'deliverableAttachmentSummary',
    getWbsItemUuid: getWbsItemUuidFromNode,
    getAttachmentSummary,
  })
  const tagsCol = useTags({
    field: 'wbsItem.tags',
    getWbsItemProjectUuid,
  })

  // Path group column common def
  const pathCol = usePath('path')
  const parentWbsItemCol = useParentWbsItem({
    field: 'parentWbsItem',
    getParentWbsItem,
  })

  // Name group column common def
  const onCloseWbsItemDetail = useCallback(
    (wbsItem: WbsItemSearchRow) =>
      wbsItem?.uuid && reloadSingleRow?.(wbsItem.uuid),
    [reloadSingleRow]
  )
  const displayNameColCellRendererParams = useMemo(
    () => ({
      onCloseWbsItemDetail,
    }),
    [onCloseWbsItemDetail]
  )
  const displayNameCol = useDisplayName({
    field: 'wbsItem.displayName',
    wbsItemCellRendererParams: displayNameColCellRendererParams,
  })
  const priorityCol = usePriority({
    field: 'wbsItem.priority',
    customEnumOptions: selectColumnOptionMap['priority'],
  })
  const difficultyCol = useDifficulty({
    field: 'wbsItem.difficulty',
    customEnumOptions: selectColumnOptionMap['difficulty'],
  })

  // Explanation group column common def
  const descriptionCol = useDescription('wbsItem.description')
  const latestCommentCol = useLatestComment('commentSummary.latestComment')

  // Assignee group column common def
  const teamCol = useTeam({
    field: 'wbsItem.team',
    gridOptionContext: selectColumnOptionMap,
  })
  const accountableCol = useAccountable({
    field: 'wbsItem.accountable',
  })
  const responsibleCol = useResponsible({
    field: 'wbsItem.responsible',
  })
  const assigneeCol = useAssignee({
    field: 'wbsItem.assignee',
  })
  const watchersCol = useWatchers({
    field: 'wbsItem.watchers',
  })

  // Estimated group column common def
  const estimatedWorkLoadDeliverableCol = useEstimatedWorkLoadDeliverable({
    field: 'wbsItem.estimatedWorkload.deliverable',
    getWbsItemType,
  })
  const estimatedWorkLoadTaskCol = useEstimatedWorkLoadTask({
    field: 'wbsItem.estimatedWorkload.task',
    getWbsItemType,
  })
  const estimatedStoryPoint = useEstimatedStoryPoint(
    'wbsItem.estimatedStoryPoint'
  )

  // Progress group column common def
  const estimatedProgressRateCol = useEstimatedProgressRate(
    'progress.scheduledProgressRate'
  )
  const progressRateCol = useProgressRate('progress.progressRate')
  const plannedValueCol = usePlannedValue('progress.plannedValue')
  const earnedValueCol = useEarnedValue('progress.completed')
  const precedingCol = usePreceding('progress.preceding')
  const delayedCol = useDelayed('progress.delayed')
  const remainingCol = useRemaining('progress.remaining')

  // Productivity group column common def
  const actualHourCol = useActualHour({
    field: 'wbsItem.actualHour',
    getWbsItemType: getBaseWbsItemType,
    getCumulation,
    hasWbsItem,
  })
  const costVarianceCol = useCostVariance('evm.costVariance')
  const costPerformanceIndexCol = useCostPerformanceIndex(
    'evm.costPerformanceIndex'
  )
  const scheduleVarianceCol = useScheduleVariance('evm.scheduleVariance')
  const schedulePerformanceIndexCol = useSchedulePerformanceIndex(
    'evm.schedulePerformanceIndex'
  )
  const estimateToCompleteCol = useEstimateToComplete('evm.estimateToComplete')
  const estimateAtCompletionCol = useEstimateAtCompletion(
    'evm.estimateAtCompletion'
  )
  const varianceAtCompletionCol = useVarianceAtCompletion(
    'evm.varianceAtCompletion'
  )

  // Term group column common def
  const sprintCol = useSprint({
    field: 'wbsItem.sprint',
    getWbsItemProjectUuid,
    getTeamUuid,
  })

  const setScheduledEndDate = useCallback(
    (
      params: ValueSetterParams<WbsItemSearchRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem?.scheduledDate) {
        params.data.wbsItem.scheduledDate = {}
      }
      params.data.wbsItem.scheduledDate.endDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData['wbsItem.scheduledDate.endDate'] = oldValue
    },
    []
  )
  const scheduledStartDateCol = useScheduledStartDate({
    field: 'wbsItem.scheduledDate.startDate',
    getScheduledDate,
    setScheduledEndDate,
  })

  const setScheduledStartDate = useCallback(
    (
      params: ValueSetterParams<WbsItemSearchRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem?.scheduledDate) {
        params.data.wbsItem.scheduledDate = {}
      }
      params.data.wbsItem.scheduledDate.startDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData['wbsItem.scheduledDate.startDate'] = oldValue
    },
    []
  )
  const scheduledEndDateCol = useScheduledEndDate({
    field: 'wbsItem.scheduledDate.endDate',
    getScheduledDate,
    setScheduledStartDate,
  })

  const setActualEndDate = useCallback(
    (
      params: ValueSetterParams<WbsItemSearchRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem?.actualDate) {
        params.data.wbsItem.actualDate = {}
      }
      params.data.wbsItem.actualDate.endDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData['wbsItem.actualDate.endDate'] = oldValue
    },
    []
  )
  const actualStartDateCol = useActualStartDate({
    field: 'wbsItem.actualDate.startDate',
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualEndDate,
  })

  const setActualStartDate = useCallback(
    (
      params: ValueSetterParams<WbsItemSearchRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem?.actualDate) {
        params.data.wbsItem.actualDate = {}
      }
      params.data.wbsItem.actualDate.startDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData['wbsItem.actualDate.startDate'] = oldValue
    },
    []
  )
  const actualEndDateCol = useActualEndDate({
    field: 'wbsItem.actualDate.endDate',
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualStartDate,
  })

  // Update info group column common def
  const revisionCol = useRevision('revision')
  const createdByCol = useCreatedBy('createdBy')
  const createdAtCol = useCreatedAt('createdAt')
  const updatedByCol = useUpdatedBy('updatedBy')
  const updatedAtCol = useUpdatedAt('updatedAt')

  const columnDefs = useMemo(
    () => [
      {
        externalId: 'uuid',
        ...uuidCol,
      },
      {
        externalId: 'rowNumber',
        field: 'rowNumber',
        type: [ColumnType.wbsItemSequenceNo],
        valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
          return (params?.node?.rowIndex ?? 0) + 1
        },
      },
      // Basic information
      {
        externalId: 'wbsItem.search.basic',
        headerName: intl.formatMessage({ id: 'projectPlan.information' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.code',
            ...codeCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.type',
            ...typeCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.ticketType',
            ...ticketTypeCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.status',
            ...statusCol,
            onCellClicked: (e: CellClickedEvent<WbsItemSearchRow>) => {
              if (!onClickedStatusCell) return
              onClickedStatusCell(e.event?.target ?? undefined, e.data)
            },
          },
          {
            externalId: 'wbsItem.search.wbsItem.substatus',
            ...substatusCol,
          },
          {
            externalId: 'wbsItem.search.action',
            ...actionCol,
            lockPosition: false,
          },
          {
            externalId: 'wbsItem.search.attachment',
            ...attachmentsCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.tags',
            ...tagsCol,
          },
        ] as FlagxsColumnDef[],
      },
      // Path
      {
        externalId: 'wbsItem.search.path',
        headerName: intl.formatMessage({ id: 'wbsItemSearch.path' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.path',
            ...pathCol,
          },
          {
            externalId: 'wbsItem.search.parentWbsItem.displayName',
            ...parentWbsItemCol,
          },
        ] as FlagxsColumnDef[],
      },
      // Name
      {
        externalId: 'wbsItem.search.name',
        headerName: intl.formatMessage({ id: 'wbsItemSearch.name' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.displayName',
            ...displayNameCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.priority',
            ...priorityCol,
            width: 65,
          },
          {
            externalId: 'wbsItem.search.wbsItem.difficulty',
            ...difficultyCol,
            width: 65,
            hide: true,
          },
        ] as FlagxsColumnDef[],
      },
      // Explanation
      {
        externalId: 'wbsItem.search.explanation',
        headerName: intl.formatMessage({
          id: 'projectPlan.description.comment',
        }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.description',
            ...descriptionCol,
            width: 165,
          },
          {
            externalId: 'wbsItem.search.commentSummary.latestComment',
            ...latestCommentCol,
            width: 235,
          },
        ] as FlagxsColumnDef[],
      },
      // Assignment
      {
        externalId: 'wbsItem.search.assignment',
        headerName: intl.formatMessage({ id: 'projectPlan.assignment' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.team',
            ...teamCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.accountable',
            ...accountableCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.responsible',
            ...responsibleCol,
          },
          {
            externalId: 'ticket.list.wbsItem.assignee',
            ...assigneeCol,
          },
          {
            externalId: 'ticket.list.wbsItem.watchers',
            ...watchersCol,
          },
        ] as FlagxsColumnDef[],
      },
      // Estimated
      {
        externalId: 'wbsItem.search.estimate',
        headerName: intl.formatMessage({ id: 'projectPlan.estimated' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.estimatedWorkload.deliverable',
            ...estimatedWorkLoadDeliverableCol,
            valueSetter: estimatedWorkLoadValueSetter,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (!data || data.wbsItem?.wbsItemType?.isTask()) {
                return undefined
              }
              const deliverableEstimatedHour = getDeliverableEstimatedHour(data)
              return formatWorkload(
                deliverableEstimatedHour,
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.wbsItem.estimatedWorkload.task',
            ...estimatedWorkLoadTaskCol,
            valueSetter: estimatedWorkLoadValueSetter,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              const taskEstimatedHour = getTaskEstimatedHour(data)
              return formatWorkload(
                taskEstimatedHour,
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.wbsItem.estimatedStoryPoint',
            ...estimatedStoryPoint,
          },
        ] as FlagxsColumnDef[],
      },
      // Progress
      {
        externalId: 'wbsItem.search.progress',
        headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
        children: [
          {
            externalId: 'wbsItem.search.estimatedProgressRate',
            ...estimatedProgressRateCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (data?.wbsItem?.wbsItemType?.isTask()) return '-'
              const plannedValue = getTaskPlannedValue(data)
              const estimatedHour = getTaskEstimatedHour(data)
              return getRate(plannedValue, estimatedHour)
            },
          },
          {
            externalId: 'wbsItem.search.progressRate',
            ...progressRateCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const earnedValue = getTaskEarnedValue(data)
              const estimatedHour = getTaskEstimatedHour(data)
              return getRate(earnedValue, estimatedHour)
            },
          },
          {
            externalId: 'wbsItem.search.plannedValue',
            ...plannedValueCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                data?.wbsItem?.wbsItemType?.isTask() &&
                (!isScheduledToBeDone(data) || isDiscard(data))
              ) {
                return ''
              }
              return formatEvm(
                getTaskPlannedValue(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.earnedValue',
            ...earnedValueCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (data?.wbsItem?.wbsItemType?.isTask() && !isDone(data)) {
                return ''
              }
              return (
                getTaskEarnedValue(data) /
                (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
              )
            },
          },
          {
            externalId: 'wbsItem.search.preceding',
            ...precedingCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              return formatEvm(
                getPrecedingTaskWorkload(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.delayedWorkload',
            ...delayedCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              return formatEvm(
                getDelayedTaskWorkload(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.remainingWorkload',
            ...remainingCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (data?.wbsItem?.wbsItemType?.isTask()) {
                return isDone(data) || isDiscard(data)
                  ? 0
                  : formatEvm(
                      getTaskEstimatedHour(data),
                      params.context.workloadUnitState?.hoursPerSelectedUnit ||
                        1
                    )
              }
              return formatEvm(
                getTaskEstimatedHour(data) - getTaskEarnedValue(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
        ] as FlagxsColumnDef[],
      },
      // Productivity
      {
        externalId: 'ticket.list.productivity',
        headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.actualHour',
            ...actualHourCol,
            field: 'wbsItem.actualHour',
            hide: false,
            width: 120,
            onCellClicked: (e: CellClickedEvent<WbsItemSearchRow>) => {
              if (
                !e.value ||
                e.value === 0.0 ||
                !e.data?.wbsItem?.baseWbsItemType?.isTask() ||
                !onClickedActualHourCell
              ) {
                return
              }
              onClickedActualHourCell(e.data)
            },
          },
          {
            externalId: 'wbsItem.search.costVariance',
            ...costVarianceCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const earnedValue = getTaskEarnedValue(data)
              const actualCost = getActualHour(data)
              return formatEvm(
                earnedValue - actualCost,
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.costPerformanceIndex',
            ...costPerformanceIndexCol,
            hide: false,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const earnedValue = getTaskEarnedValue(data)
              const actualCost = getActualHour(data)
              return getRate(earnedValue, actualCost)
            },
          },
          {
            externalId: 'wbsItem.search.scheduleVariance',
            ...scheduleVarianceCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const earnedValue = getTaskEarnedValue(data)
              const plannedValue = getTaskPlannedValue(data)
              return formatEvm(
                earnedValue - plannedValue,
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.schedulePerformanceIndex',
            ...schedulePerformanceIndexCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const earnedValue = getTaskEarnedValue(data)
              const plannedValue = getTaskPlannedValue(data)
              return getRate(earnedValue, plannedValue)
            },
          },
          {
            externalId: 'wbsItem.search.estimateToComplete',
            ...estimateToCompleteCol,
            hide: false,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              return formatEvm(
                getTaskEstimateToComplete(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.estimateAtCompletion',
            ...estimateAtCompletionCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              return formatEvm(
                getTaskEstimateAtCompletion(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
          {
            externalId: 'wbsItem.search.varianceAtCompletion',
            ...varianceAtCompletionCol,
            valueGetter: (params: ValueGetterParams<WbsItemSearchRow>) => {
              const data: WbsItemSearchRow | undefined = params.data
              if (
                !data ||
                (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))
              ) {
                return ''
              }
              const bac = getTaskEstimatedHour(data)
              const eac = getTaskEstimateAtCompletion(data)
              return formatEvm(
                bac - eac,
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
            },
          },
        ] as FlagxsColumnDef[],
      },
      // Term
      {
        externalId: 'wbsItem.search.term',
        headerName: intl.formatMessage({ id: 'projectPlan.term' }),
        children: [
          {
            externalId: 'wbsItem.search.wbsItem.sprint',
            ...sprintCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.scheduledDate.startDate',
            ...scheduledStartDateCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.scheduledDate.endDate',
            ...scheduledEndDateCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.actualDate.startDate',
            ...actualStartDateCol,
          },
          {
            externalId: 'wbsItem.search.wbsItem.actualDate.endDate',
            ...actualEndDateCol,
          },
        ] as FlagxsColumnDef[],
      },
      // Update info
      {
        externalId: 'wbsItem.search.updateInfo',
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          {
            externalId: 'wbsItem.search.revision',
            ...revisionCol,
          },
          {
            externalId: 'wbsItem.search.createdBy',
            ...createdByCol,
          },
          {
            externalId: 'wbsItem.search.createdAt',
            ...createdAtCol,
          },
          {
            externalId: 'wbsItem.search.updatedBy',
            ...updatedByCol,
          },
          {
            externalId: 'wbsItem.search.updatedAt',
            ...updatedAtCol,
          },
        ] as FlagxsColumnDef[],
      },
    ],
    [
      uuidCol,
      codeCol,
      typeCol,
      ticketTypeCol,
      statusCol,
      substatusCol,
      actionCol,
      attachmentsCol,
      tagsCol,
      pathCol,
      parentWbsItemCol,
      displayNameCol,
      priorityCol,
      difficultyCol,
      descriptionCol,
      latestCommentCol,
      teamCol,
      accountableCol,
      responsibleCol,
      assigneeCol,
      watchersCol,
      estimatedWorkLoadDeliverableCol,
      estimatedWorkLoadTaskCol,
      estimatedStoryPoint,
      estimatedProgressRateCol,
      progressRateCol,
      plannedValueCol,
      earnedValueCol,
      precedingCol,
      delayedCol,
      remainingCol,
      actualHourCol,
      costVarianceCol,
      costPerformanceIndexCol,
      scheduleVarianceCol,
      schedulePerformanceIndexCol,
      estimateToCompleteCol,
      estimateAtCompletionCol,
      varianceAtCompletionCol,
      sprintCol,
      scheduledStartDateCol,
      scheduledEndDateCol,
      actualStartDateCol,
      actualEndDateCol,
      revisionCol,
      createdByCol,
      createdAtCol,
      updatedByCol,
      updatedAtCol,
      onClickedStatusCell,
      onClickedActualHourCell,
    ]
  )
  return columnDefs
}
